import React from 'react';
import { Grid, Typography, Card, CardContent, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de deplegar la informacion de Terrestre Nacional del Booking Seleccionado
Componente padre: MasterBookingSearchResult
Componente hijo: Sin compónente
props: props.data = informacion a desplegar
*/

export default function MasterBookingDetails(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
		>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t('booking.detalles.infoBooking')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.referenciaCliente')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.vReferenciaCliente}
								</Typography>
							</Grid>
							{props.data.bBuyersConsolidator && (
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t(
											'booking.detalles.buyersConsolidator'
										)}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.bBuyersConsolidator}
									</Typography>
								</Grid>
							)}
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('booking.detalles.servicios')}
								</Typography>
								{props.data.servicios.map((servicio) => (
									<Typography
										color='initial'
										class='Datos-bookings'
										key={servicio.UiCodBooking}
									>
										{servicio.vDescripcionTipoModalidad +
											' ' +
											servicio.vDescripcionModalidad}
									</Typography>
								))}
							</Grid>
						</Stack>
					</CardContent>
				</Card>

				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t('catalogos.consignatario.consignatario')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('catalogos.misDirecciones.nombre', {
										obligatorio: '',
									})}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{
										props.data.consignatario.persona
											.vNombreORazonSocial
									}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('dataGrid.pais')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.consignatario.persona.pais}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
			<Stack
				direction={{
					xs: 'column',
					sm: 'column',
					md: 'row',
					lg: 'row',
					xl: 'row',
				}}
				spacing={1}
			>
				<Card className='paper-hover' sx={{ width: '100%' }}>
					<CardContent>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'column',
								lg: 'column',
								xl: 'column',
							}}
							spacing={1}
						>
							<Grid item container>
								<Typography variant='subtitle1' color='initial'>
									{t('booking.detalles.embarcador')}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('catalogos.misDirecciones.nombre', {
										obligatorio: '',
									})}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{
										props.data.embarcador.persona
											.vNombreORazonSocial
									}
								</Typography>
							</Grid>
							<Grid item>
								<Typography
									variant='body2'
									class='Datos-bookings-titulos'
									color='initial'
								>
									{t('dataGrid.pais')}
								</Typography>
								<Typography
									color='initial'
									class='Datos-bookings'
								>
									{props.data.embarcador.persona.pais}
								</Typography>
							</Grid>
						</Stack>
					</CardContent>
				</Card>
				{props.data.notify.persona && (
					<Card className='paper-hover' sx={{ width: '100%' }}>
						<CardContent>
							<Stack
								direction={{
									xs: 'column',
									sm: 'column',
									md: 'column',
									lg: 'column',
									xl: 'column',
								}}
								spacing={1}
							>
								<Grid item container>
									<Typography
										variant='subtitle1'
										color='initial'
									>
										{t('booking.detalles.notify')}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('catalogos.misDirecciones.nombre', {
											obligatorio: '',
										})}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{
											props.data.notify.persona
												.vNombreORazonSocial
										}
									</Typography>
								</Grid>
								<Grid item>
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{t('dataGrid.pais')}
									</Typography>
									<Typography
										color='initial'
										class='Datos-bookings'
									>
										{props.data.notify.persona.pais}
									</Typography>
								</Grid>
							</Stack>
						</CardContent>
					</Card>
				)}
			</Stack>
		</Stack>
	);
}
