import * as React from 'react';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import MySkeletonPage from '../../Skeletons/SkeletonPage';
import GetDataFetch from '../../get-data/GetDataFetch';
import ComplementosDePagoTabla from './ComplementosDePagoTabla';

/*Descripción: Este componente se encarga de renderizar el DataGrid de la información estado de cuenta del cliente
Componente padre: EstadoDeCuentaCard //
	IndexEC
Componente hijo: EstadoDeCuentaTable
props: props.endpoint = endpoint al que se hará la petición
*/

export default function ComplementosDePago(props) {
	// Se declaran los const para su uso en ComplementosDePago
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const navigate = useNavigate();
	const rows = [];

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		let url = props.endpoint + '/all';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	// useEffect Data Fetch
	useEffect(() => {
		dataFetch();
	}, [props.endpoint]);

	return (
		<Box
			sx={{
				marginTop: '0.5%',
				bgcolor: 'background.secondary',
				paddingLeft: 1,
				borderRadius: 1,
			}}
		>
			{loading ? (
				<MySkeletonPage />
			) : (
				<ComplementosDePagoTabla
					dataDetalles={dataDetalles}
					rows={rows}
				/>
			)}
		</Box>
	);
}
