import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, esES } from '@mui/x-data-grid';
import {
	Grid,
	InputAdornment,
	Snackbar,
	TextField,
	Typography,
	Tooltip,
	IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Search } from '@mui/icons-material';
import MuiAlert from '@mui/material/Alert';
import Cookies from 'universal-cookie';
import { Visibility } from '@mui/icons-material';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar la informacion de las filas del DataGrid del Estado de Cuenta
y exportar la información a un archivo CSV
Componente padre: EstadoDeCuenta
Componente hijo:
props:props.dataDetalles = Información a desplegar de las facturas //
	props.headCells = columnas del DataGrid
*/

export default function ComplementosDePagoTabla(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	let folio, year, empresa, month;
	const navigate = useNavigate();
	// Datos para la tabla
	const tableData = [];
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Se crean los const para el uso de los alerts globales
	const [alertMessagePage, setAlertMessagePage] = useState({
		type: '',
		message: '',
	});
	const [snackbarPage, setSnackbarPage] = useState({
		open: false,
		message: ' ',
	});
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const handleClosePage = () => {
		setSnackbarPage({ open: false, message: '' });
	};
	// Se crean los parametros del metodo Patch
	var myHeaders = new Headers();
	myHeaders.append(
		'Content-Type',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	);
	myHeaders.append('Authorization', 'Bearer ' + token);

	// Funcion para darle formato a la fecha
	function formato(fecha) {
		if (fecha !== '') {
			var info = fecha.split('/');
			return info[2] + '/' + info[1] + '/' + info[0];
		} else {
			return '';
		}
	}

	// Funcion para obtener los datos de la factura
	const handleCellClick = (params) => {
		if (params.row.empresa === 'SAT Internacional') {
			empresa = 'sat';
		} else if (params.row.empresa === 'Fast Forward') {
			empresa = 'fast';
		} else if (params.row.empresa === 'Intercom') {
			empresa = 'intercom';
		}
		folio = params.row.folio;
		year = params.row.fechaAlta.slice(0, 4);
		month = params.row.fechaAlta.slice(5, 7);
		navigate(
			`/detalle-complemento?folio=${folio}&year=${year}&month=${month}&empresa=${empresa}`
		);
	};

	// Se declaran las columnas del DataGrid
	const headCells = [
		{
			id: 'folio',
			field: 'folio',
			width: 110,
			headerName: t('dataGrid.folio'),
			editable: false,
		},
		{
			id: 'folioFiscal',
			field: 'folioFiscal',
			width: 270,
			headerName: t('dataGrid.folioFiscal'),
			editable: false,
		},
		{
			id: 'empresa',
			field: 'empresa',
			width: 120,
			headerName: t('dataGrid.empresa'),
			editable: false,
		},
		{
			id: 'fechaAlta',
			field: 'fechaAlta',
			width: 150,
			renderCell: (params) => (
				<span title={params.value}>{formato(params.value)}</span>
			),
			headerName: t('dataGrid.fechaComplemento'),
			editable: false,
		},
		{
			id: 'acciones',
			field: 'acciones',
			width: 65,
			headerName: t('dataGrid.acciones'),
			editable: false,
			renderCell: (params) => {
				return (
					<IconButton
						id='verMasIcon'
						className='primary'
						aria-label='add an alarm'
						sx={{ cursor: 'pointer' }}
						onClick={() => {
							handleCellClick(params);
						}}
					>
						<Tooltip
							id='verMasIcon'
							title={t('catalogos.consignatario.verMas')}
						>
							<Visibility id='verMasIcon' color='primary' />
						</Tooltip>
					</IconButton>
				);
			},
		},
	];

	// Se crea un arreglo con la información de las complementos
	for (let i = 0; i < props.dataDetalles.complementos.length; i++) {
		const complementos = props.dataDetalles.complementos[i];
		const fechaAlta = formato(complementos.fechaAlta);
		// Se valida que la factura exista
		if (complementos) {
			// Si existe se asignan los valores correspondientes a las columnas del DataGrid
			const rowData = {
				id: `Complemento ${i + 1}`,
				folioFiscal: complementos.folioFiscal,
				folio: complementos.complementoPago,
				fechaAlta: fechaAlta,
				empresa: complementos.empresa,
			};
			// Se agrega la información a la fila
			tableData.push(rowData);
		}
	}

	// Funciones para la busqueda del DataGrid
	const [searchTerm, setSearchTerm] = useState('');

	const filterRows = () => {
		return tableData.filter((row) =>
			headCells.some((cell) => {
				const cellValue = String(row[cell.id]).toLowerCase();
				return cellValue.includes(searchTerm.toLowerCase());
			})
		);
	};

	// Funcion para traducir el DataGrid
	const localeText = () => {
		if (
			t('dataGrid.idioma') ===
			'esES.components.MuiDataGrid.defaultProps.localeText'
		) {
			return esES.components.MuiDataGrid.defaultProps.localeText;
		} else {
			return '';
		}
	};

	return (
		<>
			<Grid container>
				<Grid
					item
					lg={12}
					md={12}
					sm={12}
					xs={12}
					sx={{ paddingBottom: '1.5%', paddingTop: '1%' }}
				>
					<Typography
						variant='body2'
						color='initial'
						class='Datos-bookings-titulos'
					>
						{t('tablero.estadoresumen.informacion')}
					</Typography>
					<Typography
						variant='body2'
						class='Datos-bookings-titulos'
						color='initial'
					>
						{t('dataGrid.aclaracionesContactarse')}
					</Typography>
				</Grid>
			</Grid>
			<Grid container>
				<Grid container paddingBottom={'1%'}>
					<Grid item lg={6} md={6} sm={6} xs={6}>
						<TextField
							label={t('tablero.trackTrace.buscar')}
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							autoComplete='off'
							variant='outlined'
							size='small'
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Search />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
				</Grid>
				<Grid container>
					<Box
						style={{
							height: '54vh',
							width: '100%',
							paddingRight: '1%',
							paddingBottom: '1%',
						}}
					>
						<DataGrid
							localeText={localeText()}
							rows={filterRows()}
							columns={headCells}
							initialState={{
								pagination: {
									paginationModel: {
										page: 0,
										pageSize: 20,
									},
								},
							}}
							pageSizeOptions={[20, 50, 100]}
							sx={{
								'& .MuiDataGrid-cell': {
									fontSize: '12px',
									fontFamily: 'Source Sans Pro',
								},
								'& .MuiDataGrid-columnHeaderTitle': {
									fontSize: '13px',
									fontFamily: 'Open Sans',
								},
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<Snackbar
				open={snackbarPage.open}
				autoHideDuration={10000}
				onClose={handleClosePage}
				anchorOrigin={{ vertical, horizontal }}
			>
				<Alert
					onClose={handleClosePage}
					severity={alertMessagePage.severity}
				>
					{alertMessagePage.message}
				</Alert>
			</Snackbar>
		</>
	);
}
