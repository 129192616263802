import {
	Box,
	Card,
	CardContent,
	Grid,
	Stack,
	Tab,
	Tabs,
	Typography,
} from '@mui/material';
import MapasCargas from './MapasCargas';
import MapaRutas from './MapaRutas';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los despachos del Booking Master
Componente padre: MapasServicios
Componente hijo: MapaRutas // MapasCargas
props: props.despacho = informacion de los despachos a desplegar // props.isSmallScreen = Funcion para validar el tamaño de la pantalla //
props.value = Valor del tab seleccionado // props.handleChange = Funcion para cambiar el tab seleccionado //
props.handleOpen = Funcion para abrir el modal de la carga seleccionada // props.servicio = informacion del servicio seleccionado
*/

export default function BookingInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<Stack
			direction={props.isSmallScreen ? 'column' : 'row'}
			spacing={1}
			sx={{ width: '100%' }}
		>
			<Card
				className='paper-hover'
				width={props.isSmallScreen ? '11%' : '100%'}
			>
				<CardContent>
					<Tabs
						orientation={
							props.isSmallScreen ? 'horizontal' : 'vertical'
						}
						value={props.value}
						onChange={props.handleChange}
						aria-label='Vertical tabs example'
					>
						{props.servicio.rutas.map((ruta) => (
							<Tab
								label={`${t('booking.estatus.ruta')} ${
									ruta.nRuta
								}`}
								sx={{ color: 'grey' }}
								key={ruta.nRuta}
							/>
						))}
					</Tabs>
				</CardContent>
			</Card>
			{props.servicio.rutas.map(
				(ruta) =>
					ruta.nRuta === props.value + 1 && (
						<Box sx={{ width: '100%' }} key={ruta.nRuta}>
							<Grid item width={'100%'}>
								<Stack
									direction={
										props.isSmallScreen ? 'column' : 'row'
									}
									spacing={1}
								>
									<Grid item width={'100%'}>
										<Card
											width={'100%'}
											elevation={1}
											className='paper-hover'
										>
											<CardContent>
												{props.servicio.tipoEquipo
													.vDescripcionTipoEquipo && (
													<Stack direction='row'>
														<Typography
															variant='body2'
															class='Datos-bookings-titulos'
															color='initial'
														>
															{t(
																'booking.detalles.tipoEquipo'
															)}
														</Typography>
														<Typography
															color='initial'
															className='Datos-bookings'
														>
															{
																props.servicio
																	.tipoEquipo
																	.vDescripcionTipoEquipo
															}
														</Typography>
													</Stack>
												)}
												{ruta.recoleccion &&
												ruta.entrega ? (
													<Stack direction='row'>
														<MapaRutas
															ruta={
																ruta.recoleccion
															}
														/>
														<Grid
															item
															alignContent={
																'center'
															}
														>
															<ArrowForward color='primary' />
														</Grid>
														<MapaRutas
															ruta={ruta.entrega}
														/>
													</Stack>
												) : (
													<Grid item>
														<Typography
															variant='body2'
															class='Datos-bookings-titulos'
															color='initial'
														>
															{t(
																'eventos.sinInformacionRuta'
															)}
														</Typography>
													</Grid>
												)}
											</CardContent>
										</Card>
									</Grid>

									<Grid
										item
										width={
											props.isSmallScreen ? '100%' : '70%'
										}
										maxHeight={'40vh'}
										overflow={'auto'}
									>
										<Card
											width={'100%'}
											elevation={1}
											className='paper-hover'
										>
											<CardContent>
												<MapasCargas
													ruta={ruta}
													handleOpen={
														props.handleOpen
													}
													cargas={ruta.cargas}
												/>
											</CardContent>
										</Card>
									</Grid>
								</Stack>
							</Grid>
						</Box>
					)
			)}
		</Stack>
	);
}
