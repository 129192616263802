import { Edit } from '@mui/icons-material';
import { FormControlLabel, IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'universal-cookie';
import { styled } from '@mui/material/styles';

/*Descripción:Este componente se encarga de renderizar el boton de editar
Componente padre: ListContactos.jsx
Componente hijo: EditarContactosModal.jsx
props: id: id del consignatario // idContacto: id del contacto //
dataFetch: Función para obtener los datos de la API
*/

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function EditIconDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	const navigate = useNavigate();
	const avalibleExtensions = [
		'pdf',
		'png',
		'jpeg',
		'xlsx',
		'xls',
		'zip',
		'rar',
	];

	const handleFileChange = (event) => {
		// Acceder al archivo seleccionado
		var archivo = event.target.files[0];
		if (!archivo) {
		} else {
			const extension = archivo.name.split('.')[1];
			if (avalibleExtensions.includes(extension)) {
				var formdata = new FormData();
				formdata.append('archivo', event.target.files[0]);
				const requestOptions = {
					method: 'PATCH',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow',
				};
				// Se hace el llamado al metodo Patch
				fetch(
					process.env.REACT_APP_API_ACCUTRACK_URL +
						'booking/documentos/update?cod_documento=' +
						props.idDocumento,
					requestOptions
				)
					.then((response) => {
						if (response.status === 200) {
							// Si es correcto se actualiza el contenido y se manda el mensaje de exito
							props.setAlertMessage({
								type: 'success',
								message: t('alertas.documentoEditado'),
							});
							props.handleClick1();
							setTimeout(() => {
								props.dataFetchDocumentosAgregados();
							}, 3000);
						} else if (response.status === 401) {
							// Si el token expiro se redirecciona al login
							navigate('/', {
								state: process.env.REACT_APP_ONE_VALID_TOKEN,
							});
						} else {
							// Si ocurre un error se manda el mensaje de error
							props.setAlertMessage({
								severity: 'error',
								message: t('alertas.documentoEditadoError'),
							});
							props.handleClick1();
						}
					})
					.catch((error) => console.log('error', error));
			} else {
				// Si el archivo no es valido se manda el mensaje de error
				props.setAlertMessage({
					severity: 'error',
					message: t('alertas.documentoInvalido'),
				});
				props.handleClick1();
			}
		}
	};

	return (
		<>
			<FormControlLabel
				control={
					<Tooltip title={t('botones.editar')}>
						<IconButton
							variant='contained'
							color='primary'
							component='label'
							id='editIconButtonDataGrid'
						>
							<Edit />
							<VisuallyHiddenInput
								type='file'
								onChange={handleFileChange}
							/>
						</IconButton>
					</Tooltip>
				}
			/>
		</>
	);
}
