import React, { useEffect, useState } from 'react';
import { Grid, Typography, Stack, Card, CardContent } from '@mui/material';
import InfoContenedor from './Info/InfoContenedor';
import InfoDespacho from './Info/InfoDespacho';
import TimelineSelector from './Timeline/TimelineSelector';
import SelectedSearchContenedor from '../SelectedSearchContenedor';
import { useTranslation } from 'react-i18next';
import GetRoleFetch from '../../../get-data/GetRoleFetch';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos del booking Maritimo Expo
Componente padre: BookingMaritimoImpoSR
Componente hijo: SelectedSearchContenedor //
	InfoContenedorMaritimo //
	InfoDespachoMaritimo //
	TimelineSelector
props: props.data = informacion a desplegar
*/

export default function EventosBooking(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se crean los const para el uso de los eventos del booking
	const [dataContenedor, setContenedor] = useState({});
	const [role, setRole] = useState('');

	const getInfoRole = async () => {
		await GetRoleFetch(setRole);
	};

	// Funcion para actualizar el contenedor
	function actualizarContenedor(index) {
		setContenedor(props.data.contenedores[index]);
	}

	// useEffect para la seleccion del contenedor
	useEffect(() => {
		if (props.data.contenedores.length !== 0) {
			// Si el contenedor existe, asigna el primero para mostrar
			setContenedor(props.data.contenedores[0]);
			getInfoRole();
		}
	}, [props.data]);

	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'row',
				lg: 'row',
				xl: 'row',
			}}
			spacing={1}
		>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<Grid container sx={{ paddingBottom: '3%' }}>
						{props.data.contenedores.length === 0 ? (
							<Typography variant='body1' color='initial'>
								{t('booking.estatus.sinContenedores')}
							</Typography>
						) : (
							<SelectedSearchContenedor
								actualizarContenedor={actualizarContenedor}
								customLabel={t('booking.estatus.contenedor')}
								id='contenedores'
								name='contenedores'
								dataOption={props.data.contenedores}
							/>
						)}
					</Grid>
					<Grid container spacing={2}>
						<InfoContenedor
							dataContenedor={dataContenedor}
							dataDespacho={props.data['despacho-aduanal']}
							data={props.data}
							role={role}
						/>
						<InfoDespacho
							dataContenedor={dataContenedor}
							dataDespacho={props.data['despacho-aduanal']}
							data={props.data}
							role={role}
						/>
					</Grid>
				</CardContent>
			</Card>
			<Card
				className={props.className}
				elevation={props.elevation}
				sx={{ width: '100%' }}
			>
				<CardContent>
					<TimelineSelector
						dataDespacho={props.data['despacho-aduanal']}
						dataContenedor={dataContenedor}
						data={props.data}
						role={role}
					/>
				</CardContent>
			</Card>
		</Stack>
	);
}
