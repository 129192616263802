import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Tab,
	Tabs,
	Box,
	LinearProgress,
	Typography,
	Grid,
	Container,
	CssBaseline,
} from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';

export default function BookingWithTabsMasterBooking(props) {
	const { t } = useTranslation();
	const [value, setValue] = useState('1');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<>
			<CssBaseline />
			<Container
				container
				rowSpacing={1}
				sx={{ height: '72vh' }}
				maxWidth={false}
				disableGutters
			>
				<Grid
					container
					sx={{
						justifyContent: 'space-between',
						paddingBottom: '15px',
						bgcolor: 'background.secondary',
						borderTopLeftRadius: 5,
						borderTopRightRadius: 5,
					}}
				>
					<Grid
						item
						sx={{
							paddingLeft: '10px',
							paddingTop: '6px',
						}}
					>
						<Typography variant='h6'>
							{props.selectedBooking}
						</Typography>
					</Grid>
				</Grid>
				<Grid container spacing={2}>
					<Box sx={{ width: '100%' }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderColor: 'divider',
									marginLeft: '16px',
									bgcolor: 'background.secondary',
									borderBottomLeftRadius: 5,
									borderBottomRightRadius: 5,
									marginBottom: '5px',
								}}
							>
								<Tabs onChange={handleChange} value={value}>
									<Tab
										label={t('booking.detalles.titulo')}
										id='tabDetalles'
										value='1'
										wrapped
										sx={{ color: 'grey' }}
									/>
									<Tab
										label={t('booking.estatus.titulo')}
										id='tabEstatusBooking'
										value='2'
										wrapped
										sx={{ color: 'grey' }}
									/>

									<Tab
										label={t('booking.documentos.titulo')}
										id='tabDocumentos'
										value='4'
										wrapped
										sx={{ color: 'grey' }}
									/>
								</Tabs>
							</Box>
							{props.loading ? (
								<Box marginLeft={2}>
									<LinearProgress />
								</Box>
							) : (
								<>
									<TabPanel value='1'>
										{props.TabPanelDetalles}
									</TabPanel>
									<TabPanel value='2'>
										{props.TabPanelEstatus}
									</TabPanel>
								</>
							)}
							{props.loadingDocumentos || props.loading ? (
								<></>
							) : (
								<TabPanel value='4'>
									{props.TabPanelDocumentos}
								</TabPanel>
							)}
						</TabContext>
					</Box>
				</Grid>
			</Container>
		</>
	);
}
