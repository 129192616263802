import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GetDataFetch from '../get-data/GetDataFetch';
import MasterBookingDetails from './MasterBookingDetails';
import MasterBookingEstatus from './MasterBookingEstatus';
import BookingWithTabsMasterBooking from '../titles/BookingsWithTabsMasterBooking';
import FileUploadBookingMasterModal from '../file-upload/FileUploadBookingMasterModal';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla de Booking Master
Componente padre: CommonBooking
Componente hijo: BookingWithTabsMasterBooking //
MasterBookingDetails //
MasterBookingEstatus //
FileUploadBookingMasterModal //
props: props.selectedBooking = booking seleccionado o ingreso //
	props.endpoint = endpoint al que se hará la petición
*/

export default function MasterBookingSearchResult(props) {
	// Se crean las constantes para el uso del SR
	const [loading, setLoading] = useState(true);
	const [dataDetalles, setDataDetalles] = useState({});
	const [tiposDocumentos, setTiposDocumentos] = useState([]);
	const navigate = useNavigate();
	const [loadingDocumentos, setLoadingDocumentos] = useState(true);
	const [state, setState] = useState({
		open: false,
		vertical: 'bottom',
		horizontal: 'right',
	});

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		// Se crea el endpoint
		var url = props.endpoint;
		url = url.replace(/\/all$/, '/details?booking=');
		url += props.selectedBooking;
		// Se hace el Get Fetch de la información
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};

	const dataFetchTipoDocumentos = async () => {
		setLoadingDocumentos(true);
		// Se crea el endpoint
		var url = 'catalogos/documentos/bookings/master/all';
		// Se hace el Get Fetch de la información
		await GetDataFetch(
			url,
			setLoadingDocumentos,
			setTiposDocumentos,
			navigate
		);
	};

	// useEffect para la selección del booking predeterminado
	useEffect(() => {
		if (props.selectedBooking !== '') {
			dataFetch();
			dataFetchTipoDocumentos();
			// caracteresClave();
		}
	}, [props.selectedBooking]);

	return (
		<>
			<BookingWithTabsMasterBooking
				selectedBooking={props.selectedBooking}
				TabPanelDetalles={<MasterBookingDetails data={dataDetalles} />}
				TabPanelEstatus={
					<MasterBookingEstatus
						data={dataDetalles}
						loading={loading}
					/>
				}
				TabPanelDocumentos={
					<FileUploadBookingMasterModal
						modal={false}
						selectedBooking={props.selectedBooking}
						tiposDocumentos={tiposDocumentos}
						state={state}
						data={dataDetalles}
					/>
				}
				loading={loading}
				loadingDocumentos={loadingDocumentos}
			/>
		</>
	);
}
