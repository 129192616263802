import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LocationOffIcon from '@mui/icons-material/LocationOff';
/*Descripción:Este componente se encarga de renderizar una pantalla cuando no hay booking asociado al modulo
Componente padre: GLobal
Componente hijo: CardFotos
props: Sin uso de props
*/

export default function SinEventos() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Box sx={{ height: '30vh' }}>
			<Grid
				container
				spacing={3}
				sx={{ paddingLeft: '1%', paddingTop: '3%' }}
			>
				<Grid className='Aligner ' item lg={12} md={12} sm={12} xs={12}>
					<LocationOffIcon fontSize='large' color='primary' />
				</Grid>
				<Grid className='Aligner' item lg={12} md={12} sm={12} xs={12}>
					<Typography variant='h7'>
						{t('cards.sinEventos')}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
