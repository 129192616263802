import { useLocation, useNavigate } from 'react-router-dom';
import MasterBookingSearchResult from '../master-booking/MasterBookingSearchResult';
import { Grid, IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';

export default function InterludeBookingMaster() {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const booking = searchParams.get('booking');
	const navigate = useNavigate();
	const handleClose = () => {
		navigate('/inicio');
	};
	return (
		<>
			<Grid item sx={{ textAlign: 'left' }}>
				<IconButton
					size='small'
					id='cerrarButton'
					variant='outlined'
					className='cerrarIconButton'
					onClick={handleClose}
				>
					<ArrowBack />
				</IconButton>
			</Grid>
			<MasterBookingSearchResult
				selectedBooking={booking}
				endpoint={'bookings/master/all'}
			/>
		</>
	);
}
