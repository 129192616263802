import React from 'react';
import {
	Box,
	Grid,
	Typography,
	Modal,
	Stack,
	Tooltip,
	IconButton,
	useMediaQuery,
	Card,
	CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import TimelineEventosCarga from './TimeLineEventosCarga';
import { Close } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de la carga seleccionada
Componente padre: CargasModal
Componente hijo: TimeLineEventosCarga
props: props.open = estado del modal abierto o cerrado // props.handleClose = Funcion para cerrar el modal //
props.infoCarga = informacion de la carga seleccionada
*/

export default function CargasModal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Este componente se encarga de mostrar el modal de las cargas
	const theme = useTheme();
	// Se obtiene el ancho de la pantalla
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '75%';
	const minHeight = isSmallScreen ? '80vh' : '30vh';
	// Se crean los estilos del Modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: minWidth,
		minWidth: minWidth,
		maxHeight: '95%',
		minHeight: minHeight,
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};

	return (
		<Modal open={props.open} onClose={props.handleClose}>
			<Box sx={style}>
				<Grid item textAlign={'right'}>
					<Stack
						direction='row'
						spacing={2}
						justifyContent='space-between'
					>
						<Typography variant='h6'>
							{t('eventos.eventos')}
						</Typography>
						<Tooltip title={t('botones.cerrar')}>
							<IconButton
								onClick={props.handleClose}
								id='cargaModalCerrarButton'
								variant='outlined'
								color='inherit'
								className='cerrarIconButton'
							>
								<Close />
							</IconButton>
						</Tooltip>
					</Stack>
				</Grid>
				<Card sx={{ width: '100%' }} elevation={0}>
					<CardContent
						sx={{
							minHeight: minHeight,
							maxHeight: '50vh',
							overflow: 'auto',
						}}
					>
						{props.infoCarga[0] && (
							<TimelineEventosCarga
								eventos={props.infoCarga[0].eventos}
							/>
						)}
					</CardContent>
				</Card>
			</Box>
		</Modal>
	);
}
