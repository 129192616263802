import React from 'react';
import { Stack, Grid, Typography } from '@mui/material';

/*Descripción:Este componente se encarga de desplegar la informacion de las rutas del Booking Master
Componente padre: BookingInfo
Componente hijo: Sin componentes hijos
props: props.ruta = informacion de la ruta a desplegar
*/

export default function MapaRutas(props) {
	function validateData(data) {
		if (data === undefined || data === null || data === '') {
			return '';
		} else {
			return data;
		}
	}
	return (
		<Stack
			direction={{
				xs: 'column',
				sm: 'column',
				md: 'column',
				lg: 'column',
				xl: 'column',
			}}
			spacing={1}
			sx={{ width: '100%' }}
		>
			{props.ruta !== undefined && (
				<Stack
					direction={'column'}
					spacing={2}
					border={2}
					borderRadius={2}
					borderColor={'divider'}
					padding={1}
				>
					<Grid item width={'100%'}>
						<Typography
							variant='body2'
							class='Datos-bookings-titulos'
							color='initial'
						>
							{props.ruta.vNombreAlmacen}
						</Typography>
					</Grid>
					<Grid item minHeight={80} width={'100%'}>
						<Typography color='initial' class='Datos-bookings'>
							{validateData(props.ruta.vCalleAlmacen) +
								' ' +
								validateData(props.ruta.vNoExtAlmacen)}
						</Typography>
						{props.ruta.vNoIntAlmacen && (
							<Typography color='initial' class='Datos-bookings'>
								{validateData(props.ruta.vNoIntAlmacen)}
							</Typography>
						)}
						<Typography color='initial' class='Datos-bookings'>
							{validateData(props.ruta.vLocalidadAlmacen) +
								' ' +
								validateData(
									props.ruta.vMunicipioDelegacionAlmacen
								) +
								' ' +
								validateData(props.ruta.vZipCodeAlmacen)}
						</Typography>
					</Grid>
				</Stack>
			)}
		</Stack>
	);
}
