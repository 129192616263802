import { Inventory } from '@mui/icons-material';
import {
	Divider,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de las cargas de la ruta seleccionada
Componente padre: BookingInfo
Componente hijo: Sin componentes hijos
props: props.ruta = informacion de la ruta a desplegar // props.handleOpen = Funcion para abrir el modal de la carga seleccionada
*/

export default function MapasCargas(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	return (
		<List>
			{props.ruta.cargas[0] &&
				props.ruta.cargas.map((carga) => {
					return (
						<>
							<ListItemButton
								key={carga.UiCodCarga}
								onClick={() => {
									props.handleOpen(props.ruta.cargas);
								}}
							>
								<ListItemIcon>
									<Inventory color='primary' />
								</ListItemIcon>
								<ListItemText
									primary={
										<Typography
											variant='body2'
											class='Datos-bookings-titulos'
											color='initial'
										>
											{carga.VDescripcionProducto +
												'/' +
												carga.VDescripcionProductoIngles}
										</Typography>
									}
									secondary={
										<>
											<Typography
												color='initial'
												className='Datos-bookings'
											>
												{t('booking.detalles.peso') +
													' ' +
													carga.NPesoBrutoSistemaMetrico +
													' ' +
													t(
														'booking.detalles.cantidad'
													) +
													' ' +
													carga.NCantidad +
													' ' +
													t(
														'catalogos.misEmbalajes.embalaje'
													) +
													' ' +
													carga.vDescripcionEmbalaje +
													' '}
											</Typography>
											{carga.vDescripcionIncoterm && (
												<Typography
													color='initial'
													className='Datos-bookings'
												>
													{t(
														'booking.estatus.incoterm'
													) +
														' ' +
														carga.vDescripcionIncoterm}
												</Typography>
											)}
											{carga.vDescripcionUN && (
												<Typography
													color='initial'
													className='Datos-bookings'
												>
													{t(
														'catalogos.productos.numeroUN'
													) +
														' ' +
														carga.vDescripcionUN}
												</Typography>
											)}
										</>
									}
								/>
							</ListItemButton>
							<Divider variant='inset' component='li' />
						</>
					);
				})}
		</List>
	);
}
