import React from 'react';
import TimeLineItem from './TimeLineItem';
import Timeline from '@mui/lab/Timeline';
import { Grid } from '@mui/material';
import SinEventos from '../../cards/SinEventos';
import TimeLineItemEta from './TimeLineItemETA';

/*Descripción:Este componente se encarga de desplegar la informacion de los eventos de la carga seleccionada
Componente padre: CargasModal
Componente hijo: TimeLineItem // SinEventos // TimeLineItemETA
props: props.eventos = informacion de los eventos a desplegar
*/

export default function TimelineEventosCarga(props) {
	// Funcion para validar los campos nulos o indefinidos
	const isInvalidEndpointField = (field) => {
		return field === 'N.D.' || field === undefined || field === null;
	};

	// Funcion para renderizar los eventos
	const renderTimeLine = (fechaEvento, etaEvento, nombreEvento) => {
		if (isInvalidEndpointField(fechaEvento)) {
			return <></>;
		}
		if (fechaEvento === '') {
			return <TimeLineItemEta data={etaEvento} title={nombreEvento} />;
		} else {
			return <TimeLineItem data={fechaEvento} title={nombreEvento} />;
		}
	};

	return (
		<>
			{props.eventos[0] !== undefined ? (
				<Timeline position='alternate'>
					{props.eventos.map((evento) => {
						return renderTimeLine(
							evento['dtFechaHoraConfirmacionEvento'],
							evento['dtFechaHoraEstimadaEvento'],
							evento.nombreEvento
						);
					})}
				</Timeline>
			) : (
				<Grid item xs={12}>
					<SinEventos />
				</Grid>
			)}
		</>
	);
}
