import { Box } from '@mui/material';
import React from 'react';
import ErrorBoundary from '../../components/cards/ErrorBoundary';
import CommonBooking from '../../components/CommonBooking';
import { useTranslation } from 'react-i18next';
import BoxTitle from '../../components/titles/BoxTitle';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla Terrestre Centroamerica
y mandar el endpoint a usar
Componente padre: CommonLayout.jsx
Componente hijo: CommonBooking.jsx
props: Sin uso de props
*/

export default function MasterBookingIndex() {
	const { t } = useTranslation();

	return (
		// Carga el contenido de la página
		<ErrorBoundary>
			<Box container>
				<BoxTitle title={t('booking.bookings')} />
				<Box>
					<CommonBooking
						resultComponent='MasterBookingSearchResult'
						endpoint='bookings/master/all'
					/>
				</Box>
			</Box>
		</ErrorBoundary>
	);
}
