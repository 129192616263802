import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SinInformacion from '../cards/SinInformacion';
import ServiciosSelectSearch from './ServiciosSelectSearch';
import MapasServicios from './mapas/MapasServicios';

/*Descripción:Este componente se encarga de desplegar la informacion de el estatus de el booking seleccionado y su Timeline
Componente padre: MasterBookingSearchResult
Componente hijo:ServiciosSelectSearch //
	MapasServicios
props: props.data = informacion a desplegar
*/

export default function MasterBookingEstatus(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const [servicioSeleccionado, setServicioSeleccionado] = useState({
		value: props.data.servicios[0].UiCodBooking,
		label:
			props.data.servicios[0].vDescripcionTipoModalidad +
			' ' +
			props.data.servicios[0].vDescripcionModalidad,
	});
	// Se crean los const para el uso de los eventos del Booking
	const [dataOptions, setDataOptions] = useState([]);
	const arr = [];
	useEffect(() => {
		if (props.data.servicios !== undefined) {
			props.data.servicios.map((servicio, index) => {
				// Se valida que el index de las secuencias exista
				return arr.push({
					value: servicio.UiCodBooking,
					label:
						servicio.vDescripcionTipoModalidad +
						' ' +
						servicio.vDescripcionModalidad,
				});
			});
			setDataOptions(arr);
		}
	}, [props.data.servicios]);

	// Funcion para actualizar la informacion del servicio
	function actualizarServicio(event) {
		setServicioSeleccionado(event);
	}

	return props.data.servicios === undefined ? (
		<Box>
			<SinInformacion />
		</Box>
	) : (
		<>
			<Card
				className='paper-hover'
				sx={{
					width: '100%',
					overflow: 'visible',
				}}
			>
				<CardContent>
					<ServiciosSelectSearch
						required
						customLabel={t('selectSearch.servicio')}
						id='servicio'
						dataOptions={dataOptions}
						actualizarDato={actualizarServicio}
						defaultValue={dataOptions[0]}
						servicioSeleccionado={servicioSeleccionado}
					/>
				</CardContent>
			</Card>
			<Box sx={{ minHeight: '250px' }}>
				<MapasServicios
					servicios={props.data.servicios}
					servicioSeleccionado={servicioSeleccionado}
				/>
			</Box>
		</>
	);
}
