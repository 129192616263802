import { Box, Grid, Typography, Button } from '@mui/material';
import { ReceiptLong } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

/*Descripción:Este componente se encarga de renderizar una pantalla cuando no hay booking asociado al modulo
Componente padre: GLobal
Componente hijo: CardFotos
props: Sin uso de props
*/

export default function SinFacturaAzure({ texto, navigateRoute }) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const navigate = useNavigate();

	// Funcion para regresar a la pantalla de estado de cuenta
	function onClickHome() {
		navigate(navigateRoute);
	}

	return (
		<Box
			sx={{
				position: 'absolute',
				top: '50%',
				left: '50%',
				transform: 'translate(-50%, -50%)',
				maxWidth: '350px',
			}}
		>
			<Grid container spacing={2} sx={{ paddingLeft: '1%' }}>
				<Grid className='Aligner ' item lg={12} md={12} sm={12} xs={12}>
					<ReceiptLong fontSize='large' color='primary' />
				</Grid>
				<Grid className='Aligner' item lg={12} md={12} sm={12} xs={12}>
					<Typography variant='h5' width={'450px'} align='center'>
						{texto}
					</Typography>
				</Grid>
				<Grid
					container
					spacing={0}
					justifyContent='center'
					paddingTop={3}
				>
					<Button onClick={onClickHome} variant='text'>
						{t('botones.regresar')}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
}
