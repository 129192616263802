import React from 'react';
import Box from '@mui/material/Box';
import {
	Button,
	Grid,
	Modal,
	LinearProgress,
	Snackbar,
	Card,
	CardContent,
	useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';
import DataGridFilesBookingMaster from './BookingMaster/DataGridFilesBookingMaster';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de desplegar el modal para la carga de archivos en el Booking Master
Componente padre: MasterBookingSearchResult
Componente hijo: DataGridFilesBookingMaster
props: props.openModal = estado del modal // props.handleCloseModal = funcion para cerrar el modal //
props.selectedBooking = booking seleccionado // props.data = informacion de los servicios //
props.tiposDocumentos = informacion de los tipos de documentos
*/

export default function FileUploadBookingMasterModal(props) {
	const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState({
		value: props.tiposDocumentos.documentos[0].codUso,
		label: props.tiposDocumentos.documentos[0].descripcionIdioma,
	});
	const [dataOptionsDocumentos, setDataOptionsDocumentos] = useState([]);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se configura el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: minWidth,
		maxHeight: '98%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Const para el uso del componente
	const [loadingSSDocumentos, setLoadingSSDocumentos] = useState(true);
	// Const para el uso del alert
	const [alertMessage, setAlertMessage] = useState({
		type: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({
		open: false,
		message: ' ',
	});
	const { vertical, horizontal } = props.state;
	// Evento click botón Guardar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};

	// funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	useEffect(() => {
		setLoadingSSDocumentos(true);
		if (props.tiposDocumentos.documentos !== undefined) {
			const arr = [];
			props.tiposDocumentos.documentos.map((documento, index) => {
				// Se valida que el index de las secuencias exista
				return arr.push({
					value: documento.codUso,
					label: documento.descripcionIdioma,
				});
			});
			setDataOptionsDocumentos(arr);
		}
		setLoadingSSDocumentos(false);
	}, [props.tiposDocumentos.documentos]);

	const resultToRender = () => {
		if (props.modal) {
			return (
				<>
					{loadingSSDocumentos ? (
						<LinearProgress />
					) : (
						<Modal open={props.openModal}>
							<Box sx={style}>
								{/* <Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={1}
								>
									<Grid item lg={9} md={9} sm={9} xs={9}>
										<Typography variant='h6'>
											{props.selectedBooking}
										</Typography>
									</Grid>
									<Grid
										item
										lg={3}
										md={3}
										sm={3}
										xs={3}
										sx={{ textAlign: 'right' }}
									>
										<Tooltip title={t('botones.cerrar')}>
											<IconButton
												onClick={props.handleCloseModal}
												id='cargaModalCerrarButton'
												variant='outlined'
												color='inherit'
												className='cerrarIconButton'
											>
												<Close />
											</IconButton>
										</Tooltip>
									</Grid>
									<Grid item lg={6} md={8} sm={8} xs={8}>
										<SelectSearchDocumentosBookingMaster
											required
											customLabel={t(
												'fileUpload.tipoDocumento'
											)}
											id='tipoDocumento'
											dataOptions={dataOptionsDocumentos}
											actualizarDato={actualizarDocumento}
											defaultValue={
												dataOptionsDocumentos[0]
											}
											tipoDocumentoSeleccionado={
												tipoDocumentoSeleccionado
											}
										/>
									</Grid>
									<Grid
										item
										sx={{
											paddingTop: '2%',
											marginTop: 3,
										}}
									>
										{tipoDocumentoSeleccionado ===
										undefined ? (
											<Tooltip
												title={t(
													'fileUpload.seleccioneTipoDocumento'
												)}
											>
												<Box
													style={{
														display: 'inline-block',
														pointerEvents: 'auto',
													}}
												>
													<Button
														variant='outlined'
														color='primary'
														component='label'
														id='BtnAgregarCargaBL'
														sx={{
															fontSize: '12px',
															paddingTop: '2%',
															marginBottom: 1,
														}}
														disabled={true}
														startIcon={
															<CloudUpload />
														}
													>
														{t('botones.agregar')}
														<VisuallyHiddenInput
															type='file'
															onChange={
																handleFileChange
															}
														/>
													</Button>
												</Box>
											</Tooltip>
										) : (
											<LoadingButton
												loading={loading}
												variant='outlined'
												color='primary'
												component='label'
												id='BtnAgregarCargaBL'
												sx={{
													fontSize: '12px',
													paddingTop: '2%',
													marginBottom: 1,
												}}
												disabled={false}
												startIcon={<CloudUpload />}
											>
												{t('botones.agregar')}
												<VisuallyHiddenInput
													type='file'
													onChange={handleFileChange}
												/>
											</LoadingButton>
										)}
									</Grid>
								</Grid> */}
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<DataGridFilesBookingMaster
										selectedBooking={props.selectedBooking}
										setAlertMessage={setAlertMessage}
										handleClick1={handleClick1}
										data={props.data.servicios}
									/>
								</Grid>
								<Grid
									item
									lg={12}
									md={12}
									sm={12}
									xs={12}
									sx={{
										textAlign: 'left',
										paddingTop: '2%',
									}}
								>
									<Button
										variant='outlined'
										color='error'
										onClick={props.handleCloseModal}
										id='cargaModalCerrarButton'
									>
										{t('botones.cancelar')}
									</Button>
								</Grid>
								<Snackbar
									open={snackbar1.open}
									autoHideDuration={10000}
									onClose={handleClose1}
									anchorOrigin={{ vertical, horizontal }}
								>
									<Alert
										onClose={handleClose1}
										severity={alertMessage.severity}
									>
										{alertMessage.message}
									</Alert>
								</Snackbar>
							</Box>
						</Modal>
					)}
				</>
			);
		} else if (!props.modal) {
			return (
				<>
					{loadingSSDocumentos ? (
						<LinearProgress />
					) : (
						<Card className='paper-hover'>
							<CardContent>
								{/* <Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={1}
								>
									<Grid item lg={6} md={8} sm={8} xs={8}>
										<SelectSearchDocumentosBookingMaster
											required
											customLabel={t(
												'fileUpload.tipoDocumento'
											)}
											id='tipoDocumento'
											dataOptions={dataOptionsDocumentos}
											actualizarDato={actualizarDocumento}
											defaultValue={
												dataOptionsDocumentos[0]
											}
											tipoDocumentoSeleccionado={
												tipoDocumentoSeleccionado
											}
										/>
									</Grid>
									<Grid
										item
										sx={{
											paddingTop: '2%',
											marginTop: 3,
										}}
									>
										{tipoDocumentoSeleccionado ===
										undefined ? (
											<Tooltip
												title={t(
													'fileUpload.seleccioneTipoDocumento'
												)}
											>
												<Box
													style={{
														display: 'inline-block',
														pointerEvents: 'auto',
													}}
												>
													<Button
														variant='outlined'
														color='primary'
														component='label'
														id='BtnAgregarCargaBL'
														sx={{
															fontSize: '12px',
															paddingTop: '2%',
															marginBottom: 1,
														}}
														disabled={true}
														startIcon={
															<CloudUpload />
														}
													>
														{t('botones.agregar')}
														<VisuallyHiddenInput
															type='file'
															onChange={
																handleFileChange
															}
														/>
													</Button>
												</Box>
											</Tooltip>
										) : (
											<LoadingButton
												loading={loading}
												variant='outlined'
												color='primary'
												component='label'
												id='BtnAgregarCargaBL'
												sx={{
													fontSize: '12px',
													paddingTop: '2%',
													marginBottom: 1,
												}}
												disabled={false}
												startIcon={<CloudUpload />}
											>
												{t('botones.agregar')}
												<VisuallyHiddenInput
													type='file'
													onChange={handleFileChange}
												/>
											</LoadingButton>
										)}
									</Grid>
								</Grid> */}
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<DataGridFilesBookingMaster
										selectedBooking={props.selectedBooking}
										setAlertMessage={setAlertMessage}
										handleClick1={handleClick1}
										data={props.data.servicios}
									/>
								</Grid>
								<Snackbar
									open={snackbar1.open}
									autoHideDuration={10000}
									onClose={handleClose1}
									anchorOrigin={{ vertical, horizontal }}
								>
									<Alert
										onClose={handleClose1}
										severity={alertMessage.severity}
									>
										{alertMessage.message}
									</Alert>
								</Snackbar>
							</CardContent>
						</Card>
					)}
				</>
			);
		}
	};

	useEffect(() => {
		resultToRender();
	}, [props.modal]);

	return resultToRender();
}
