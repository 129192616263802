import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

export default function TimeLineItem(props) {
	return (
		<TimelineItem>
			<TimelineSeparator>
				<Fade in={true} timeout={500}>
					<TimelineConnector sx={{ bgcolor: '#e73333' }} />
				</Fade>
				<Fade in={true} timeout={500}>
					<TimelineDot sx={{ bgcolor: '#e73333' }}>
						<CheckIcon
							sx={{
								height: '15px',
								width: '15px',
							}}
						/>
					</TimelineDot>
				</Fade>
				<Fade in={true} timeout={500}>
					<TimelineConnector sx={{ bgcolor: '#e73333' }} />
				</Fade>
			</TimelineSeparator>
			<TimelineContent sx={{ py: '12px', px: 2 }}>
				<Typography variant='subtitle2' component='span'>
					{props.title}
				</Typography>
				<Typography variant='body2'>{props.data}</Typography>
			</TimelineContent>
		</TimelineItem>
	);
}
