import * as React from 'react';
import SideBarItem from '../../components/sidebar/SideBarItem';
import SideBarItemCollapse from '../../components/sidebar/SideBarItemCollapse';
import SideBarItemCollapseTerrestre from '../../components/sidebar/SideBarItemCollapseTerrestre';
import SideBarItemCollapseMaritimo from '../../components/sidebar/SideBarItemCollapseMaritimo';
import {
	LocalShipping,
	Flight,
	DirectionsBoat,
	Settings,
} from '@mui/icons-material';
import AssuredWorkloadIcon from '@mui/icons-material/AssuredWorkload';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AppsIcon from '@mui/icons-material/Apps';
import { List } from '@mui/material';
import { useState, useEffect } from 'react';
import SideBarItemCollapseAduanas from './SideBarItemCollapseAduanas';
import SideBarItemCollapseConfig from './SideBarItemCollapseConfig';
import { useTranslation } from 'react-i18next';
import GetRoleFetch from '../get-data/GetRoleFetch';

/*Descripción:Este componente se encarga de mostrar los desplegables del SideBar
Componente padre: MiniDrawer
Componente hijo: SidebarItem //
	SidebarItemCollapseTerrestre
	SidebarItemCollapse
	SidebarItemCollapseAduanas
	SidebarItemCollapseMaritimo
props: Sin uso de props
*/

export default function ListMenu({ openSideBar }) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	const timeout = 500;
	// Se declaran los const para su uso en el ListMenu
	const [selectedIndex, setSelectedIndex] = useState(1);
	// Funcion para seleccionar el index
	const handleListItemClick = (index) => {
		setSelectedIndex(index);
	};
	const [role, setRole] = useState('');

	const getInfoRole = async () => {
		await GetRoleFetch(setRole);
	};

	useEffect(() => {
		getInfoRole();
	}, []);

	return (
		<List
			sx={{
				height: '100%',
				display: {
					xs: 'block',
					sm: 'block',
					md: 'block',
					lg: 'block',
				},
				bgcolor: 'background.secondary',
			}}
			component='nav'
			id='myNav'
		>
			<SideBarItem
				timeout={timeout * 1.5}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				id='Tablero-SideBar'
				primaryText={t('sidebar.tablero')}
				openSideBar={openSideBar}
				icon={AppsIcon}
				navlink='/inicio'
				selected={selectedIndex === 0}
				onClick={(event) => handleListItemClick(event, 0)}
			/>
			{/* <SideBarItem
				timeout={timeout * 1.5}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				id='MasterBooking-SideBar'
				openSideBar={openSideBar}
				primaryText='Bookings'
				icon={FormatListBulleted}
				navlink='/booking-master'
				selected={selectedIndex === 0}
				onClick={(event) => handleListItemClick(event, 0)}
			/> */}
			<SideBarItemCollapseTerrestre
				timeout={timeout * 1.75}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				openSideBar={openSideBar}
				id='Terrestre-SideBar'
				primaryText={t('sidebar.terrestre.titulo')}
				icon={LocalShipping}
				navlink='/terrestre'
			/>
			<SideBarItemCollapse
				timeout={timeout * 2}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				openSideBar={openSideBar}
				id='Aereo-SideBar'
				primaryText={t('sidebar.aereo.titulo')}
				icon={Flight}
				navlink='/aereo'
			/>
			<SideBarItemCollapseMaritimo
				timeout={timeout * 2.25}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				openSideBar={openSideBar}
				id='Marítimo-SideBar'
				primaryText={t('sidebar.maritimo.titulo')}
				icon={DirectionsBoat}
				navlink='/maritimo'
			/>
			<SideBarItemCollapseAduanas
				timeout={timeout * 2.5}
				roles={['Cliente', 'Cliente-admin']}
				userRole={role}
				openSideBar={openSideBar}
				id='Aduanas-SideBar'
				primaryText={t('sidebar.aduanas.titulo')}
				icon={AssuredWorkloadIcon}
				navlink='/aduanas'
			/>
			<SideBarItem
				timeout={timeout * 2.75}
				roles={['Cliente-admin']}
				userRole={role}
				id='EstadoCuenta-SideBar'
				openSideBar={openSideBar}
				primaryText={t('sidebar.estadoCuenta.titulo')}
				icon={ReceiptIcon}
				navlink='/estado-de-cuenta'
				selected={selectedIndex === 0}
				onClick={(event) => handleListItemClick(event, 0)}
			/>
			<SideBarItemCollapseConfig
				timeout={timeout * 3}
				roles={['Cliente-admin', 'Agente-admin']}
				userRole={role}
				id='Configuracion-SideBar'
				openSideBar={openSideBar}
				primaryText={t('sidebar.configuracion.titulo')}
				icon={Settings}
				navlink='/configuracion'
				selected={selectedIndex === 0}
				onClick={(event) => handleListItemClick(event, 0)}
			/>
		</List>
	);
}
