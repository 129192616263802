import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';
import SinEventos from '../../../../cards/SinEventos';

/*Descripción:Este componente se encarga de desplegar los eventos del Timeline de Terminal
Componente padre: TimelineSelector
Componente hijo: TImelineItem
props: props.data = informacion a desplegar
*/

export default function TimelineEventosTerminal(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['fecha-revalidacion-naviera'] === undefined &&
				props.data['fecha-revalidacion-cliente'] === undefined &&
				props.data['fecha-desconsolidacion'] === undefined && (
					<SinEventos />
				)}
			{props.data['fecha-revalidacion-naviera'] !== undefined && (
				<TimelineItem
					data={props.data['fecha-revalidacion-naviera']}
					title={t('eventos.revalidacionNaviera')}
				/>
			)}
			{props.data['fecha-revalidacion-cliente'] !== undefined && (
				<TimelineItem
					data={props.data['fecha-revalidacion-cliente']}
					title={t('eventos.revalidacionCliente')}
				/>
			)}
			{props.data['fecha-desconsolidacion'] !== undefined && (
				<TimelineItem
					data={props.data['fecha-desconsolidacion']}
					title={t('eventos.desconsolidacion')}
				/>
			)}
		</Timeline>
	);
}
