import * as React from 'react';
import TimelineItem from '../../../TimelineItem';
import Timeline from '@mui/lab/Timeline';
import { useTranslation } from 'react-i18next';
import SinEventos from '../../../../cards/SinEventos';

/*Descripción:Este componente se encarga de desplegar los campos del Timeline de los eventos del Booking
Componente padre: TimelineSelector
Componente hijo: TimelineItem
props: props.data = informacion a desplegar //
	props.dataContenedor = informacion a desplegar sobre el contenedor
*/

export default function TimelineEventosBooking(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Timeline position='alternate'>
			{props.data['etd-pol'] === undefined &&
				props.data['aviso-cliente'] === undefined &&
				props.data['fecha-arribo'] === undefined && <SinEventos />}

			{props.data['etd-pol'] !== undefined && (
				<TimelineItem
					data={props.data['etd-pol']}
					title={t('eventos.zarpe')}
				/>
			)}
			{props.data['aviso-cliente'] !== undefined && (
				<TimelineItem
					data={props.data['aviso-cliente']}
					title={t('eventos.avisoCliente')}
				/>
			)}
			{props.data['fecha-arribo'] !== undefined && (
				<TimelineItem
					data={props.data['fecha-arribo']}
					title={t('eventos.arribo')}
				/>
			)}
		</Timeline>
	);
}
