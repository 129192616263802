import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PendingActionsIcon from '@mui/icons-material/PendingActions';

/*Descripción:Este componente se encarga de renderizar una pantalla cuando no hay booking asociado al modulo
Componente padre: Global
Componente hijo: Sin componentes hijos
props: Sin uso de props
*/

export default function SinInformacion() {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<Box sx={{ height: '46vh' }}>
			<Grid
				container
				spacing={3}
				sx={{ paddingLeft: '1%', paddingTop: '3%' }}
			>
				<Grid className='Aligner ' item lg={12} md={12} sm={12} xs={12}>
					<PendingActionsIcon fontSize='large' color='primary' />
				</Grid>
				<Grid className='Aligner' item lg={12} md={12} sm={12} xs={12}>
					<Typography variant='h7' width={'450px'}>
						{t('booking.estatus.bookingSinInformacion')}
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
}
