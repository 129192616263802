import { Circle } from '@mui/icons-material';
import { Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de desplegar la informacion de los despachos del Booking Master
Componente padre: MapasServicios
Componente hijo: Sin componentes hijos
props: props.despacho = informacion de los despachos a desplegar
*/

export default function DespachoInfo(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Funcion para validar la informacion
	function validateData(data) {
		if (data === undefined || data === null) {
			return '';
		}
		return data;
	}
	for (let key in props.despacho) {
		props.despacho[key] = validateData(props.despacho[key]);
	}

	return (
		<Card>
			<CardContent sx={{ maxHeight: '38vh', overflow: 'scroll' }}>
				<Stack
					direction='row'
					spacing={4}
					justifyContent={'space-between'}
				>
					<Stack direction='column' spacing={2}>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('booking.detalles.aduana')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.despacho.Aduana}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('eventos.tipoDespacho')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.despacho.Trafico}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('eventos.arribo')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.despacho.FechaArriboImp}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('eventos.revalidacion')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.despacho.FechaRevalidacion}
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant='body2'
								class='Datos-bookings-titulos'
								color='initial'
							>
								{t('eventos.previo')}
							</Typography>
							<Typography color='initial' class='Datos-bookings'>
								{props.despacho.FechaPrevio}
							</Typography>
						</Grid>
					</Stack>
					{props.despacho.pedimento &&
						props.despacho.pedimento.map((pedimento) => {
							return (
								<>
									<Stack
										direction='column'
										spacing={2}
										key={pedimento.Pedimento}
									>
										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t(
													'booking.estatus.numeroPedimento'
												)}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.Pedimento}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t('eventos.entrega')}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.FECHAENTREGA}
											</Typography>
										</Grid>

										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t('eventos.pagoPedimento')}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.FechaPagoPedimento}
											</Typography>
										</Grid>

										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t('eventos.liberacion')}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.FECHALIBERACION}
											</Typography>
										</Grid>
									</Stack>
									<Stack
										direction='column'
										spacing={2}
										key={pedimento.Pedimento}
									>
										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t(
													'booking.estatus.modulacion'
												)}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.FechaModulacion}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t(
													'eventos.resultadoModulacion'
												)}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.ResultadoModulacion ===
												'V' ? (
													<Circle color='success' />
												) : (
													pedimento.ResultadoModulacion ===
														'R' && (
														<Circle color='error' />
													)
												)}
											</Typography>
										</Grid>

										{pedimento.Fecha2Reconocimiento !==
											'' && (
											<>
												<Grid item>
													<Typography
														variant='body2'
														class='Datos-bookings-titulos'
														color='initial'
													>
														{t(
															'eventos.segundaFechaReconocimiento'
														)}
													</Typography>
													<Typography
														color='initial'
														class='Datos-bookings'
													>
														{
															pedimento.Fecha2Reconocimiento
														}
													</Typography>
												</Grid>
												<Grid item>
													<Typography
														variant='body2'
														class='Datos-bookings-titulos'
														color='initial'
													>
														{t(
															'booking.estatus.resultadoReconocimiento'
														)}
													</Typography>
													<Typography
														color='initial'
														class='Datos-bookings'
													>
														{
															pedimento.Resultado2Rec
														}
													</Typography>
												</Grid>
											</>
										)}

										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t('eventos.tieneDemoras')}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.TieneDemoras === 1
													? t('eventos.si')
													: t('eventos.no')}
											</Typography>
										</Grid>
										<Grid item>
											<Typography
												variant='body2'
												class='Datos-bookings-titulos'
												color='initial'
											>
												{t('eventos.tieneEstadias')}
											</Typography>
											<Typography
												color='initial'
												class='Datos-bookings'
											>
												{pedimento.TieneEstadias === 1
													? t('eventos.si')
													: t('eventos.no')}
											</Typography>
										</Grid>
									</Stack>
								</>
							);
						})}
				</Stack>
			</CardContent>
		</Card>
	);
}
