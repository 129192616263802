import { Stack } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import ErrorBoundary from '../components/cards/ErrorBoundary';
import Slide from '@mui/material/Slide';
import TrackingCard from '../components/track-and-trace/TrackingCard';
import ActiveBookingTable from '../components/estadisticas/ActiveBookingTable';
import EstadoDeCuentaCard from '../components/estado-de-cuenta/EstadoDeCuentaCard';
import CarruselCardsEstadisticas from '../components/estadisticas/cards/CarruselCardsEstadisticas';
import GetRoleFetch from '../components/get-data/GetRoleFetch';

/*Descripción:Este componente es la pantalla de inicio que se encarga de mostrar las estadisticas generales del cliente
Componente padre: CommonLayout
Componente hijo: TrackingCard //
	ActiveBookingTable //
	EstadoDeCuentaCard
*/

export default function Inicio() {
	// Se declaran los const para el uso en el componente
	const [checked] = useState(true);
	const [role, setRole] = useState('');
	const [isAgente, setIsAgente] = useState(false);

	const getInfoRole = async () => {
		await GetRoleFetch(setRole);
	};

	useEffect(() => {
		getInfoRole();
	}, []);
	useEffect(() => {
		if (role.includes('Agente')) {
			console.log('Es agente');
			setIsAgente(true);
		}
	}, [role]);

	return (
		// Carga el contenido de la página===========================
		<ErrorBoundary>
			<Slide in={checked} direction='up' timeout={900}>
				{isAgente ? (
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'column',
							xl: 'column',
						}}
						spacing={1}
					>
						<TrackingCard />
						<ActiveBookingTable role={role} isAgente={isAgente} />
						{/*
						<Grid item sx={{ width: '100%' }}>
							<TrackingCard />
						</Grid>
						<Stack
							direction={{
								xs: 'column',
								sm: 'column',
								md: 'row',
								lg: 'row',
								xl: 'row',
							}}
							spacing={1}
							width={'100%'}
						>
							<Grid item sx={{ width: '100%' }}>
								<ActiveBookingTable
									role={role}
									isAgente={isAgente}
								/>
							</Grid>
							<Grid item sx={{ width: '100%' }}>
								<CarruselCardsEstadisticas />
							</Grid>
						</Stack> */}
					</Stack>
				) : (
					<Stack
						direction={{
							xs: 'column',
							sm: 'column',
							md: 'column',
							lg: 'row',
							xl: 'row',
						}}
						spacing={1}
					>
						<Grid item sx={{ width: '100%' }}>
							<Stack
								direction={{
									xs: 'column',
									sm: 'column',
									md: 'column',
									lg: 'column',
									xl: 'column',
								}}
								spacing={1}
							>
								<TrackingCard />
								<CarruselCardsEstadisticas />
								<EstadoDeCuentaCard endpoint='facturacion' />
							</Stack>
						</Grid>
						<Grid item sx={{ width: '100%' }}>
							<ActiveBookingTable
								role={role}
								isAgente={isAgente}
							/>
						</Grid>
					</Stack>
				)}
			</Slide>
		</ErrorBoundary>
	);
}
