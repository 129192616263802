import React from 'react';
import Box from '@mui/material/Box';
import {
	Button,
	Grid,
	Modal,
	LinearProgress,
	Typography,
	Tooltip,
	Snackbar,
	Card,
	CardContent,
	IconButton,
	useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import SelectSearchDocumentos from './SelectSearchDocumentos';
import { useState } from 'react';
import { useEffect } from 'react';
import GetDataFetch from '../get-data/GetDataFetch';
import { useNavigate } from 'react-router-dom';
import DataGridFiles from './DataGridFiles';
import { Close, CloudUpload } from '@mui/icons-material';
import Cookies from 'universal-cookie';
import LoadingButton from '@mui/lab/LoadingButton';
import MuiAlert from '@mui/material/Alert';
import { useTheme } from '@mui/material/styles';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

/*Descripción:Este componente se encarga de renderizar el modal de la subida de documentos
Componente padre: ActiveBookingDataGrid
Componente hijo: SelectFileUpload
props: props.openModal = funcion para abrir el modal //
	props.selectedBooking = booking seleccionado o ingresado //
	props.handelCloseModal = funcion para cuando cierre el modal
*/

const VisuallyHiddenInput = styled('input')({
	clip: 'rect(0 0 0 0)',
	clipPath: 'inset(50%)',
	height: 1,
	overflow: 'hidden',
	position: 'absolute',
	bottom: 0,
	left: 0,
	whiteSpace: 'nowrap',
	width: 1,
});

export default function FileUploadModal(props) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const minWidth = isSmallScreen ? '95%' : '60%';
	// Se configura el estilo del modal
	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		minWidth: minWidth,
		maxWidth: minWidth,
		maxHeight: '98%',
		minHeight: '40vh',
		bgcolor: 'background.paper',
		border: '1px solid #000',
		boxShadow: 24,
		p: '1.5vw',
		overflow: 'auto',
		borderRadius: '10px',
	};
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se obtiene el token de las cookies
	const cookies = new Cookies();
	const token = cookies.get('jwt_authorization');
	// Const para el uso del componente
	const [dataOptionsDocumentos, setDataOptionsDocumentos] = useState([]);
	const [loadingSSDocumentos, setLoadingSSDocumentos] = useState(true);
	const [
		dataDetallesDocumentosAgregados,
		setDataDetallesDocumentosAgregados,
	] = useState();
	const [loadingDocumentosAgregados, setLoadingDocumentosAgregados] =
		useState(true);
	const [documentoBooking, setDocumentoBooking] = useState({
		booking: props.selectedBooking,
		tipo_documento: props.infoDocumento.vDescripcionEspaniol,
	});
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const avalibleExtensions = [
		'pdf',
		'png',
		'jpeg',
		'xlsx',
		'xls',
		'zip',
		'rar',
	];
	// Const para el uso del alert
	const [alertMessage, setAlertMessage] = useState({
		type: '',
		message: '',
	});
	const [snackbar1, setSnackbar1] = useState({
		open: false,
		message: ' ',
	});
	const { vertical, horizontal } = props.state;
	// Evento click botón Guardar
	const handleClick1 = () => {
		setSnackbar1({
			open: true,
			message: alertMessage,
		});
	};

	// funcion para cerrar el Alert
	const handleClose1 = () => {
		setSnackbar1({ open: false, message: '' });
	};

	//Se crean los parametros para el metodo Patch
	var myHeaders = new Headers();
	myHeaders.append('Authorization', 'Bearer ' + token);
	const handleFileChange = (event) => {
		// Acceder al archivo seleccionado
		var archivo = event.target.files[0];
		if (!archivo) {
		} else {
			setLoading(true);
			const extension = archivo.name.split('.')[1];
			if (avalibleExtensions.includes(extension)) {
				var formdata = new FormData();
				formdata.append('archivo', event.target.files[0]);
				const requestOptions = {
					method: 'POST',
					headers: myHeaders,
					body: formdata,
					redirect: 'follow',
				};
				// Se hace el llamado al metodo Patch
				fetch(
					process.env.REACT_APP_API_ACCUTRACK_URL +
						'booking/documentos/create?tipoDocumento=' +
						props.infoDocumento.uiCodTipoDocumento +
						'&booking=' +
						props.selectedBooking,
					requestOptions
				)
					.then((response) => {
						if (response.status === 200) {
							// Si es correcto se actualiza el contenido y se manda el mensaje de exito
							setAlertMessage({
								type: 'success',
								message: t('alertas.documentoAgregado'),
							});
							handleClick1();
							props.setInfoDocumento({
								uiCodTipoDocumento: '',
								vDescripcionEspaniol: '',
								vDescripcionIngles: '',
							});
							archivo = '';
							setLoading(false);
							setTimeout(() => {
								dataFetchDocumentosAgregados();
							}, 3000);
						} else if (response.status === 401) {
							// Si el token expiro se redirecciona al login
							archivo = '';
							setLoading(false);
							navigate('/', {
								state: process.env.REACT_APP_ONE_VALID_TOKEN,
							});
						} else if (response.status === 504) {
							setLoading(false);
						} else {
							// Si es incorrecto se manda el mensaje de error
							setAlertMessage({
								severity: 'error',
								message: t('alertas.documentoAgregadoError'),
							});
							handleClick1();
							archivo = '';
							setLoading(false);
						}
					})
					.catch((error) => console.log('error', error));
			} else {
				setAlertMessage({
					severity: 'error',
					message: t('alertas.documentoInvalido'),
				});
				handleClick1();
				archivo = '';
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		setLoadingSSDocumentos(true);
		const arr = [];
		props.dataDetallesDocumentos.documentos.map((documento, index) => {
			// Se valida que el index de las secuencias exista
			return arr.push({
				value: documento.uiCodTipoDocumento,
				label: documento.vDescripcionEspaniol,
			});
		});
		setLoadingSSDocumentos(false);
		setDataOptionsDocumentos(arr);
	}, []);

	// fetch data
	const dataFetchDocumentosAgregados = async () => {
		setLoadingDocumentosAgregados(true);
		console.log(props.selectedBooking);
		let url = 'booking/documentos/all?booking=' + props.selectedBooking;
		await GetDataFetch(
			url,
			setLoadingDocumentosAgregados,
			setDataDetallesDocumentosAgregados,
			navigate
		);
	};

	useEffect(() => {
		dataFetchDocumentosAgregados();
	}, [props.selectedBooking]);

	const resultToRender = () => {
		if (props.modal) {
			return (
				<>
					{loadingSSDocumentos || loadingDocumentosAgregados ? (
						<LinearProgress />
					) : (
						<Modal open={props.openModal}>
							<Box sx={style}>
								<Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={1}
								>
									<Grid item lg={9} md={9} sm={9} xs={9}>
										<Typography variant='h6'>
											{props.selectedBooking}
										</Typography>
									</Grid>
									<Grid
										item
										lg={3}
										md={3}
										sm={3}
										xs={3}
										sx={{ textAlign: 'right' }}
									>
										<Tooltip title={t('botones.cerrar')}>
											<IconButton
												onClick={props.handleCloseModal}
												id='cargaModalCerrarButton'
												variant='outlined'
												color='inherit'
												className='cerrarIconButton'
											>
												<Close />
											</IconButton>
										</Tooltip>
									</Grid>
									<Grid item lg={6} md={8} sm={8} xs={8}>
										<SelectSearchDocumentos
											required
											customLabel={t(
												'fileUpload.tipoDocumento'
											)}
											id='tipoDocumento'
											dataOptions={dataOptionsDocumentos}
											actualizarDocumento={
												props.actualizarDocumento
											}
											name='tipoDocumento'
											indexDocumento={
												props.indexDocumento
											}
											setInfoDocumento={
												props.setInfoDocumento
											}
											infoDocumento={props.infoDocumento}
										/>
									</Grid>
									<Grid
										item
										sx={{
											paddingTop: '2%',
											marginTop: 3,
										}}
									>
										{props.infoDocumento
											.uiCodTipoDocumento === undefined ||
										props.infoDocumento
											.uiCodTipoDocumento === '' ? (
											<Tooltip
												title={t(
													'fileUpload.seleccioneTipoDocumento'
												)}
											>
												<Box
													style={{
														display: 'inline-block',
														pointerEvents: 'auto',
													}}
												>
													<Button
														variant='outlined'
														color='primary'
														component='label'
														id='BtnAgregarCargaBL'
														sx={{
															fontSize: '12px',
															paddingTop: '2%',
															marginBottom: 1,
														}}
														disabled={true}
														startIcon={
															<CloudUpload />
														}
													>
														{t('botones.agregar')}
														<VisuallyHiddenInput
															type='file'
															onChange={
																handleFileChange
															}
														/>
													</Button>
												</Box>
											</Tooltip>
										) : (
											<LoadingButton
												loading={loading}
												variant='outlined'
												color='primary'
												component='label'
												id='BtnAgregarCargaBL'
												sx={{
													fontSize: '12px',
													paddingTop: '2%',
													marginBottom: 1,
												}}
												disabled={false}
												startIcon={<CloudUpload />}
											>
												{t('botones.agregar')}
												<VisuallyHiddenInput
													type='file'
													onChange={handleFileChange}
												/>
											</LoadingButton>
										)}
									</Grid>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<DataGridFiles
										dataDetallesDocumentosAgregados={
											dataDetallesDocumentosAgregados
										}
										loadingDocumentosAgregados={
											loadingDocumentosAgregados
										}
										dataFetchDocumentosAgregados={
											dataFetchDocumentosAgregados
										}
										setDataDetallesDocumentosAgregados={
											setDataDetallesDocumentosAgregados
										}
										setLoadingDocumentosAgregados={
											setLoadingDocumentosAgregados
										}
										setDocumentoBooking={
											setDocumentoBooking
										}
										documentoBooking={documentoBooking}
										selectedBooking={props.selectedBooking}
										setAlertMessage={setAlertMessage}
										handleClick1={handleClick1}
									/>
								</Grid>
								<Grid
									item
									lg={12}
									md={12}
									sm={12}
									xs={12}
									sx={{
										textAlign: 'left',
										paddingTop: '2%',
									}}
								>
									<Button
										variant='outlined'
										color='error'
										onClick={props.handleCloseModal}
										id='cargaModalCerrarButton'
									>
										{t('botones.cancelar')}
									</Button>
								</Grid>
								<Snackbar
									open={snackbar1.open}
									autoHideDuration={10000}
									onClose={handleClose1}
									anchorOrigin={{ vertical, horizontal }}
								>
									<Alert
										onClose={handleClose1}
										severity={alertMessage.severity}
									>
										{alertMessage.message}
									</Alert>
								</Snackbar>
							</Box>
						</Modal>
					)}
				</>
			);
		} else if (!props.modal) {
			return (
				<>
					{loadingSSDocumentos || loadingDocumentosAgregados ? (
						<LinearProgress />
					) : (
						<Card>
							<CardContent>
								<Grid
									container
									direction='row'
									justifyContent='flex-start'
									alignItems='center'
									spacing={1}
								>
									<Grid item lg={6} md={8} sm={8} xs={8}>
										<SelectSearchDocumentos
											required
											customLabel={t(
												'fileUpload.tipoDocumento'
											)}
											id='tipoDocumento'
											dataOptions={dataOptionsDocumentos}
											actualizarDocumento={
												props.actualizarDocumento
											}
											name='tipoDocumento'
											indexDocumento={
												props.indexDocumento
											}
											setInfoDocumento={
												props.setInfoDocumento
											}
											infoDocumento={props.infoDocumento}
										/>
									</Grid>
									<Grid
										item
										sx={{
											paddingTop: '2%',
											marginTop: 3,
										}}
									>
										{props.infoDocumento
											.uiCodTipoDocumento === undefined ||
										props.infoDocumento
											.uiCodTipoDocumento === '' ? (
											<Tooltip
												title={t(
													'fileUpload.seleccioneTipoDocumento'
												)}
											>
												<Box
													style={{
														display: 'inline-block',
														pointerEvents: 'auto',
													}}
												>
													<Button
														variant='outlined'
														color='primary'
														component='label'
														id='BtnAgregarCargaBL'
														sx={{
															fontSize: '12px',
															paddingTop: '2%',
															marginBottom: 1,
														}}
														disabled={true}
														startIcon={
															<CloudUpload />
														}
													>
														{t('botones.agregar')}
														<VisuallyHiddenInput
															type='file'
															onChange={
																handleFileChange
															}
														/>
													</Button>
												</Box>
											</Tooltip>
										) : (
											<LoadingButton
												loading={loading}
												variant='outlined'
												color='primary'
												component='label'
												id='BtnAgregarCargaBL'
												sx={{
													fontSize: '12px',
													paddingTop: '2%',
													marginBottom: 1,
												}}
												disabled={false}
												startIcon={<CloudUpload />}
											>
												{t('botones.agregar')}
												<VisuallyHiddenInput
													type='file'
													onChange={handleFileChange}
												/>
											</LoadingButton>
										)}
									</Grid>
								</Grid>
								<Grid item lg={12} md={12} sm={12} xs={12}>
									<DataGridFiles
										dataDetallesDocumentosAgregados={
											dataDetallesDocumentosAgregados
										}
										loadingDocumentosAgregados={
											loadingDocumentosAgregados
										}
										dataFetchDocumentosAgregados={
											dataFetchDocumentosAgregados
										}
										setDataDetallesDocumentosAgregados={
											setDataDetallesDocumentosAgregados
										}
										setLoadingDocumentosAgregados={
											setLoadingDocumentosAgregados
										}
										setDocumentoBooking={
											setDocumentoBooking
										}
										documentoBooking={documentoBooking}
										selectedBooking={props.selectedBooking}
										setAlertMessage={setAlertMessage}
										handleClick1={handleClick1}
									/>
								</Grid>
								<Snackbar
									open={snackbar1.open}
									autoHideDuration={10000}
									onClose={handleClose1}
									anchorOrigin={{ vertical, horizontal }}
								>
									<Alert
										onClose={handleClose1}
										severity={alertMessage.severity}
									>
										{alertMessage.message}
									</Alert>
								</Snackbar>
							</CardContent>
						</Card>
					)}
				</>
			);
		}
	};

	useEffect(() => {
		resultToRender();
	}, [props.modal]);

	return resultToRender();
}
