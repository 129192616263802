import { Grid, Typography } from '@mui/material';
import React from 'react';
import InfoBlDataGrid from './InfoBlDataGrid';
import { useEffect } from 'react';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de renderizar el contenido de la pantalla FileUpload
Componente padre: CommonLayout.jsx //
Componente hijo: FileUploadModal
props: props.selectedBooking = booking seleccionado
*/

export default function InfoBlCarga(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	const viewToRender = () => {
		// Si la informacion de las cargas no existe regresa el texto correspondiente
		if (props.data.cargas.length === 0) {
			return (
				<Grid container spacing={1}>
					<Grid item container spacing={0} justifyContent={'center'}>
						<PrecisionManufacturingIcon
							fontSize='large'
							className='primaryIcon'
						/>
					</Grid>
					<Grid item container spacing={0} justifyContent={'center'}>
						<Typography variant='body1'>
							{t('bl.blSinCargaAsignada')}
						</Typography>
					</Grid>
				</Grid>
			);
		} else {
			// Si la informacion de la carga existe, regresa el DataGrid correspondiente
			return (
				<InfoBlDataGrid
					dataFetchBooking={props.dataFetchBooking}
					dataGrid={props.data.cargas}
					selectedBooking={props.data.booking}
					data={props.data}
					dataModalidadPago={props.dataModalidadPago}
					dataBulto={props.dataBulto}
				/>
			);
		}
	};

	useEffect(() => {
		viewToRender();
	}, [props.data.cargas]);

	return <>{viewToRender()}</>;
}
