import React from 'react';
import {
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Inventory } from '@mui/icons-material';

/*Descripción:Este componente se encarga de renderizar el DataGrid de las cargas del InfoStepper
Componente padre: ListadoCargas
Componente hijo: EditIconDataGrid // DeleteIconDataGrid
props: props.dataGrid = información a desplegar del DataGrid
*/

export default function InfoBlDataGrid(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();

	return (
		<List>
			{props.dataGrid.map((carga) => {
				return (
					<>
						<ListItem>
							<ListItemIcon>
								<Inventory color='primary' />
							</ListItemIcon>
							<ListItemText
								primary={
									<Typography
										variant='body2'
										class='Datos-bookings-titulos'
										color='initial'
									>
										{carga.descripcion_esp}
									</Typography>
								}
								secondary={
									<>
										<Typography
											color='initial'
											className='Datos-bookings'
										>
											{t('booking.detalles.peso') +
												' ' +
												carga.peso +
												' ' +
												t('booking.detalles.cantidad') +
												' ' +
												carga.cantidad_bultos +
												' ' +
												t('booking.detalles.volumen') +
												' ' +
												carga.volumen +
												' '}
										</Typography>
									</>
								}
							/>
						</ListItem>
						<Divider variant='inset' component='li' />
					</>
				);
			})}
		</List>
	);
}
