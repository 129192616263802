import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './views/Login';
import NotFound from './views/NotFound';
import { Box } from '@mui/material';
import AboutUs from './views/AboutUs';
import ProtectedRoute from './components/routes/ProtectedRoute';

// Styles
import './assets/css/index.css';
import './assets/css/app.css';
import './assets/css/styles.css';
import './assets/css/theme.css';
import './i18n';
import CommonLayout from './components/CommonLayout';
import UpdatePassword from './views/Usuario/UpdatePassword';
import RecuperaContra from './views/Usuario/RecuperaContra';
import RoleContext from './components/routes/RoleContext';
import CreatePassword from './views/Usuario/CreatePassword';
import Cookies from 'universal-cookie';
import jwt_decode from 'jwt-decode';
import IndexCentroDeAyuda from './views/CentroDeAyuda/IndexCentroDeAyuda';

/*Descripción:Este componente se encarga  de redirigirte a la pagina dependiendo del url ingresado
Componente padre: index.js
Componente hijo: CommonLayout //
	NotFound //
	AboutUs
props: Sin uso de props
*/

// Context =======================================================
export const RazonSocialContext = createContext();
// ===============================================================

function App() {
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	var token = cookies.get('jwt_authorization');
	const [loading, setLoading] = useState(false);
	// Función que asigna el token nuevo, generado al cambiar de razón social,
	// a la constante token (para realizar todas las peticiones) y a la cookie
	const actualizaTokenNuevo = (newToken) => {
		// Quita todas las cookies previas que coincidan con ese nombre
		cookies.remove('jwt_authorization');

		// Agrega la cookie con el token nuevo
		var now = new Date();
		// Calcular el tiempo de expiración en 30 minutos (en milisegundos)
		var expireTime = now.getTime() + 30 * 60 * 1000;
		var expiration = new Date(expireTime);

		cookies.set('jwt_authorization', newToken, {
			expires: expiration,
		});

		// Actualiza la constante token con el nuevo token
		token = newToken;
	};

	// Context =======================================================
	const [dataRazonesSociales, setDataRazonesSociales] = useState();

	// Método para obtener el nuevo token cuando se cambia de razón social
	const updateToken = (clavesCustomers) => {
		setLoading(true);
		// Se obtienen las claves de las razones sociales
		const customers = clavesCustomers.value.split('_');
		const customerInternova = customers[0];
		const customerLip = customers[1];

		// Realizar la petición para solicitar un nuevo token con la nueva
		// razon social solicitada
		const getNewToken = async () => {
			// Configuracion del header
			var myHeaders = new Headers();
			myHeaders.append('Content-Type', 'application/json');
			myHeaders.append('Authorization', 'Bearer ' + token);

			// Configuracion del request
			var requestOptions = {
				method: 'GET',
				headers: myHeaders,
				redirect: 'follow',
			};

			// fetch data
			const resultNewToken = await fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL +
					'customers-extra/token?customerI=' +
					customerInternova +
					'&customerL=' +
					customerLip,
				requestOptions
			);
			// Se valida el valor del estatus
			if (resultNewToken.status === 200) {
				const data = await resultNewToken.json();
				const dataNewToken = data.data.token;
				// Se asignan los datos recopilados (el TOKEN NUEVO CON LA NUEVA RAZON SOCIAL SELECCIONADA)
				actualizaTokenNuevo(dataNewToken);
				setTimeout(() => {
					setLoading(false);
				}, 2000); // 3 seconds timer
			} else {
				console.log(
					'Error al generar el token para la razón social seleccionada'
				);
				setTimeout(() => {
					setLoading(false);
				}, 2000); // 3 seconds timer
			}
		};

		getNewToken();
	};

	// ===============================================================

	const [userRole, setUserRole] = useState('');

	useEffect(() => {
		if (token !== undefined) {
			const claims = jwt_decode(token);
			const rolesClaims = claims?.roles;
			const rol = rolesClaims.substring(1, rolesClaims.length - 1);
			setUserRole(rol);
		}
	}, []);

	return (
		<RazonSocialContext.Provider
			value={{ dataRazonesSociales, setDataRazonesSociales, updateToken }}
		>
			<BrowserRouter>
				<Box sx={{ bgcolor: 'background.default' }}>
					<RoleContext.Provider value={userRole}>
						<Routes>
							{/*RUTAS SIN PROTECCION DE ROLES*/}
							<Route path='/' element={<Login />} />
							<Route path='*' element={<NotFound />} />
							<Route path='/contactanos' element={<AboutUs />} />
							<Route
								path='/user/recupera-contrasena'
								element={<RecuperaContra />}
							/>
							<Route
								path='/user/reestablecer-contrasena'
								element={<UpdatePassword />}
							/>
							<Route
								path='/create-password'
								element={<CreatePassword />}
							/>
							<Route
								path='/help'
								element={<IndexCentroDeAyuda />}
							/>

							{/*RUTAS CON PROTECCION DE ROLES*/}
							<Route
								path='/inicio'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
											'ROLE_AGENTE_EXTRANJERO',
											'ROLE_AGENTE_EXTRANJERO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/inicio'
									element={
										<CommonLayout
											view='Inicio'
											loading={loading}
										/>
									}
								/>
							</Route>
							{/*Centro de ayuda*/}
							<Route
								path='/centro-ayuda'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
											'ROLE_AGENTE_EXTRANJERO',
											'ROLE_AGENTE_EXTRANJERO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/centro-ayuda'
									element={
										<CommonLayout view='CentroAyuda' />
									}
								/>
							</Route>

							{/*USUARIOS*/}
							<Route
								path='/configuracion/usuarios'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO_ADMIN',
											'ROLE_AGENTE_EXTRANJERO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/configuracion/usuarios'
									element={
										<CommonLayout view='ListadoUsuarios' />
									}
								/>
							</Route>
							{/*TERRESTRE NACIONAL*/}
							<Route
								path='/terrestre/nacional'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/terrestre/nacional'
									element={<CommonLayout view='IndexTN' />}
								/>
							</Route>
							<Route
								path='/terrestre/nacional/carta-porte'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/terrestre/nacional/carta-porte'
									element={
										<CommonLayout view='IndexCartaPorte' />
									}
								/>
							</Route>

							{/*TERRESTRE CENTROAMERICA*/}
							<Route
								path='/terrestre/centroamerica/exportacion'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/terrestre/centroamerica/exportacion'
									element={<CommonLayout view='IndexTCAE' />}
								/>
							</Route>

							{/*AEREO*/}
							<Route
								path='/aereo/importacion'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/aereo/importacion'
									element={<CommonLayout view='IndexAI' />}
								/>
							</Route>
							<Route
								path='/aereo/exportacion'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/aereo/exportacion'
									element={<CommonLayout view='IndexAE' />}
								/>
							</Route>

							{/*ADUANAS*/}
							<Route
								path='/aduanas/aereo'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/aduanas/aereo'
									element={<CommonLayout view='IndexDA' />}
								/>
							</Route>
							<Route
								path='/aduanas/maritimo'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/aduanas/maritimo'
									element={<CommonLayout view='IndexDM' />}
								/>
							</Route>
							<Route
								path='/aduanas/terrestre'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/aduanas/terrestre'
									element={<CommonLayout view='IndexDT' />}
								/>
							</Route>

							{/*MARITIMO*/}
							<Route
								path='/maritimo/importacion'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/maritimo/importacion'
									element={<CommonLayout view='IndexMI' />}
								/>
							</Route>
							<Route
								path='/maritimo/exportacion'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/maritimo/exportacion'
									element={<CommonLayout view='IndexME' />}
								/>
							</Route>
							<Route
								path='/maritimo/bill-of-lading'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/maritimo/bill-of-lading'
									element={<CommonLayout view='IndexBl' />}
								/>
							</Route>
							<Route
								path='/maritimo/bill-of-lading'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/maritimo/bill-of-lading'
									element={<CommonLayout view='IndexBl' />}
								/>
							</Route>
							<Route
								path='/maritimo/bill-of-lading-catalogos'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/maritimo/bill-of-lading-catalogos'
									element={
										<CommonLayout view='IndexBLWithCatalog' />
									}
								/>
							</Route>

							{/*Booking Master*/}
							<Route
								path='/booking-master'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/booking-master'
									element={
										<CommonLayout view='MasterBookingIndex' />
									}
								/>
							</Route>
							<Route
								path='/booking-master/booking-details'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/booking-master/booking-details'
									element={
										<CommonLayout view='MasterBookingDetails' />
									}
								/>
							</Route>

							{/*USUARIO*/}
							<Route
								path='/usuario/perfil'
								element={
									<ProtectedRoute
										roles={[
											'ROLE_CLIENTE_DIRECTO',
											'ROLE_CLIENTE_DIRECTO_ADMIN',
											'ROLE_AGENTE_EXTRANJERO',
											'ROLE_AGENTE_EXTRANJERO_ADMIN',
										]}
									/>
								}
							>
								<Route
									path='/usuario/perfil'
									element={
										<CommonLayout view='UserProfile' />
									}
								/>
							</Route>

							{/*ADMINISTRATIVO*/}
							<Route
								path='/estado-de-cuenta'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/estado-de-cuenta'
									element={<CommonLayout view='IndexEC' />}
								/>
							</Route>
							<Route
								path='/configuracion/catalogos'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos'
									element={
										<CommonLayout view='IndexCatalogos' />
									}
								/>
							</Route>

							<Route
								path='/configuracion/catalogos/productos'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos/productos'
									element={
										<CommonLayout view='ProductsList' />
									}
								/>
							</Route>

							<Route
								path='/configuracion/catalogos/consignatario'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos/consignatario'
									element={
										<CommonLayout view='ConsignatariosLists' />
									}
								/>
							</Route>

							<Route
								path='/configuracion/catalogos/mis-direcciones'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos/mis-direcciones'
									element={
										<CommonLayout view='MisDireccionesList' />
									}
								/>
							</Route>

							<Route
								path='/configuracion/catalogos/embalajes'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos/embalajes'
									element={
										<CommonLayout view='MisEmbalajesList' />
									}
								/>
							</Route>

							<Route
								path='/configuracion/catalogos/productos-cartaporte'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/configuracion/catalogos/productos-cartaporte'
									element={
										<CommonLayout view='ProductosCartaPorteList' />
									}
								/>
							</Route>

							<Route
								path='/file-upload'
								element={<CommonLayout view='file-upload' />}
							/>

							<Route
								path='/detalle-factura'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/detalle-factura'
									element={
										<CommonLayout view='DetallesFactura' />
									}
								/>
							</Route>
							<Route
								path='/detalle-complemento'
								element={
									<ProtectedRoute
										roles={['ROLE_CLIENTE_DIRECTO_ADMIN']}
									/>
								}
							>
								<Route
									path='/detalle-complemento'
									element={
										<CommonLayout view='DetallesComplemento' />
									}
								/>
							</Route>
						</Routes>
					</RoleContext.Provider>
				</Box>
			</BrowserRouter>
		</RazonSocialContext.Provider>
	);
}

export default App;
