import React, { useState } from 'react';
import { Box } from '@mui/material';
import CargasModal from './CargasModal';
import SinInformacion from '../../cards/SinInformacion';
import { useMediaQuery, useTheme } from '@mui/material';
import BookingInfo from './BookingInfo';
import DespachoInfo from './DespachoInfo';

/*Descripción:Este componente se encarga de desplegar la informacion de los servicios del Booking Master
Componente padre: MasterBookingEstatus
Componente hijo:DespachoInfo //
	SinInformacion // BookingInfo // CargasModal
props: props.servicios = informacion de los servicios a desplegar
*/

export default function MapasServicios(props) {
	// Definir si el tamaño es menor que 'md'
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const [open, setOpen] = useState(false);
	const [infoCarga, setInfoCarga] = useState('');
	const handleOpen = (carga) => {
		setInfoCarga(carga);
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const [value, setValue] = useState(0);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return props.servicios.map((servicio) => {
		return (
			servicio.UiCodBooking === props.servicioSeleccionado.value && (
				<Box
					sx={{ width: '100%' }}
					paddingTop={1}
					key={servicio.UiCodBooking}
				>
					{servicio.despacho ? (
						<DespachoInfo despacho={servicio.despacho} />
					) : servicio.rutas.length === 0 ? (
						<SinInformacion />
					) : (
						<BookingInfo
							isSmallScreen={isSmallScreen}
							value={value}
							handleChange={handleChange}
							servicio={servicio}
							handleOpen={handleOpen}
						/>
					)}

					<CargasModal
						handleClose={handleClose}
						open={open}
						infoCarga={infoCarga}
					/>
				</Box>
			)
		);
	});
}
