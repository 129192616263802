import * as React from 'react';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { Fade } from '@mui/material';

export default function TimeLineItemEta(props) {
	return (
		<TimelineItem>
			<TimelineSeparator>
				<Fade in={true} timeout={500}>
					<TimelineConnector />
				</Fade>
				<Fade in={true} timeout={500}>
					<TimelineDot variant='outlined' />
				</Fade>
				<Fade in={true} timeout={500}>
					<TimelineConnector />
				</Fade>
			</TimelineSeparator>
			<TimelineContent sx={{ py: '12px', px: 2 }}>
				<Typography variant='body2' color={'gray'}>
					E.T.A
				</Typography>
				<Typography variant='subtitle2' component='span' color={'gray'}>
					{props.title}
				</Typography>
				<Typography variant='body2' color={'gray'}>
					{props.data}
				</Typography>
			</TimelineContent>
		</TimelineItem>
	);
}
