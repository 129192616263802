import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ActiveBookingDataGrid from './ActiveBookingDataGrid';
import GetDataFetch from '../get-data/GetDataFetch';
import { useTranslation } from 'react-i18next';

/*Descripción:Este componente se encarga de mostrar un DataGrid de los bookings activos
Componente padre: Inicio.jsx
Componente hijo: ActiveBookingDataGrid //
	Card
props: sin uso de props
*/

export default function ActiveBookingTable(props) {
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en ActiveBookingTable
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	const [dataDetalles, setDataDetalles] = useState();

	// fetch data
	const dataFetch = async () => {
		setLoading(true);
		let url = 'estaditicas/active-bookings-service';
		await GetDataFetch(url, setLoading, setDataDetalles, navigate);
	};
	// useEfect Data Fetch
	useEffect(() => {
		dataFetch();
	}, []);

	return (
		<Card className='paper-hover'>
			<CardContent>
				<Grid container spacing={1} paddingBottom={'10px'}>
					<Grid item spacing={0}>
						<ConfirmationNumberIcon
							color='primary'
							fontSize='large'
						/>
					</Grid>
					<Grid item spacing={0}>
						<Typography gutterBottom variant='h5' component='div'>
							{t('tablero.bookingsActivos.titulo')}
						</Typography>
					</Grid>
				</Grid>
				<Grid container justifyContent='center'>
					{loading ? (
						<CircularProgress />
					) : (
						<ActiveBookingDataGrid
							dataDetalles={dataDetalles}
							isAgente={props.isAgente}
							role={props.role}
						/>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
}
